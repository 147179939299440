import { css } from '@emotion/react'
import styled from '@emotion/styled'
import type {
  BackofficeSubscriptionEventDescription,
  BackofficeSubscriptionHistoryDescription,
} from '@orus.eu/backend/src/routers/subscriptions'
import { ContentContainerBackoffice, Text, jsonTreeTheme, mobileMediaQuery, spacing } from '@orus.eu/pharaoh'
import { useParams } from '@tanstack/react-router'
import { JSONTree } from 'react-json-tree'
import { trpcReact } from '../../../../client'
import { assert } from '../../../../lib/errors'
import { formatDateTimeSeconds } from '../../../../lib/format'
import { BackofficeDataCard } from '../../../molecules/backoffice-data-card'
import { CopyToClipboardButton } from '../../../molecules/copy-to-clipboard-button'

export default function PlatformPendingSubscriptionHistoryPage(): JSX.Element {
  const { subscriptionId } = useParams({ from: '/bak/pending-subscriptions/$subscriptionId/history' })
  assert(subscriptionId, 'missing subscriptionId url lparam')
  const [subscriptionHistory] = trpcReact.subscriptions.loadSubscriptionHistory.useSuspenseQuery(subscriptionId)

  return <PendingSubscription subscriptionHistory={subscriptionHistory} />
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${spacing[70]};
  ${mobileMediaQuery} {
    margin: ${spacing[50]} ${spacing[70]} 0 ${spacing[70]};
  }
`

type PendingSubscriptionProps = {
  subscriptionHistory: BackofficeSubscriptionHistoryDescription
}

function PendingSubscription({ subscriptionHistory }: PendingSubscriptionProps): JSX.Element {
  const orderedEvents = [...subscriptionHistory.events]
  orderedEvents.reverse()

  return (
    <ContentContainerBackoffice>
      <Header>
        <Text variant="h4">
          Souscription {subscriptionHistory.email || subscriptionHistory.subscriptionId} - Historique
        </Text>
      </Header>
      <CopyToClipboardButton
        stringToCopy={JSON.stringify(orderedEvents)}
        variant="secondary"
        icon="copy-regular"
        avatarPosition="left"
        css={css`
          margin-top: ${spacing[40]};
        `}
      >
        Copier les données
      </CopyToClipboardButton>
      {orderedEvents.map((event) => (
        <PendingSubscriptionEvent key={event.id} event={event} />
      ))}
    </ContentContainerBackoffice>
  )
}

type PendingSubscriptionEventProps = {
  event: BackofficeSubscriptionEventDescription
}

function PendingSubscriptionEvent({ event }: PendingSubscriptionEventProps): JSX.Element {
  return (
    <BackofficeDataCard title={`${formatDateTimeSeconds(event.timestamp)} - ${event.type}`} icon="bolt-solid">
      <EventDataBlock event={event} />
    </BackofficeDataCard>
  )
}

type EventDataBlockProps = {
  event: BackofficeSubscriptionEventDescription
}

function EventDataBlock({ event }: EventDataBlockProps): JSX.Element {
  const displayedEventProperties = Object.entries(event).filter(([key]) => key !== 'type')
  if (displayedEventProperties.length === 0) {
    return <></>
  }

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: auto auto 1fr;
        place-items: center start;
        gap: ${spacing[20]};
        margin: ${spacing[50]} ${spacing[100]};
      `}
    >
      <>
        {displayedEventProperties.map(([key, value]) => (
          <EventPropertyLine key={key} name={key} value={value} />
        ))}
      </>
    </div>
  )
}

type EventPropertyLineProps = {
  name: string
  value: unknown
}

function EventPropertyLine({ name, value }: EventPropertyLineProps): JSX.Element {
  return (
    <>
      <Text variant="body1">{name}</Text>
      <Text variant="body1">=</Text>
      <Text variant="body1">
        <JSONTree data={value} theme={jsonTreeTheme} hideRoot />
      </Text>
    </>
  )
}
